import {
  updateProfileRequest,
  getProfileResponse,
  updateProfileCMSResponse
} from './transformers';
import {
  getProfileSettings,
  getProfileSettingsString,
  getUpdateCustomerPersonalInfoString,
  getAddressString,
  getHouseHoldString,
  getContactMethodString
} from './graphqlTransformer';
import { readAuthTokenCookie } from 'Utils/auth/authUtils';
import { LoginResponse } from '../auth/models';
import { PROFILE_TYPES } from 'Redux/profile/profileConstants';
export default class CMSProfileApiClient {
  getProfile(userId, loginId, partyUid) {
    if (!partyUid) {
      throw new Error('User ID must be provided to profile fetch');
    }
    let authTokenCookie = readAuthTokenCookie();

    return fetch(
      `${CONFIG.API.CMS.HOST}/api/profile/irw/${CONFIG.COUNTRY}/${partyUid}/`,
      {
        method: 'GET',
        headers: {
          ...this.headers,
          'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
          ...(authTokenCookie && {
            Authorization: `Bearer ${authTokenCookie}`
          })
        }
      }
    )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        let profile = getProfileResponse(body);
        profile.userId = userId;
        profile.userLogin = loginId;
        return profile;
      })
      .catch(error => {
        throw error;
      });
  }

  getProfileGraphql(partyUid) {
    if (!partyUid) {
      throw new Error('User ID must be provided to profile fetch');
    }
    let authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      },
      body: JSON.stringify({
        query: `{getCustomerProfile(retailUnit: ${CONFIG.COUNTRY} ) {customerIdentity { firstName lastName mobile email memberId phoneVerified emailVerified loyaltyProgramCode preferredStore zipCode }}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (
          body &&
          body.data &&
          body.data.getCustomerProfile &&
          body.data.getCustomerProfile.customerIdentity
        ) {
          return body.data.getCustomerProfile.customerIdentity;
        } else {
          throw Error();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  update(currentProfile, updatedProfile, partyUid, mode) {
    const profileData = JSON.stringify(
      updateProfileRequest(currentProfile, updatedProfile)
    );
    var authTokenCookie = readAuthTokenCookie();
    const serviceUrl = `${CONFIG.API.CMS.HOST}/api/profile/irw/${CONFIG.COUNTRY}/${partyUid}/`;
    return new Promise((resolve, reject) => {
      fetch(mode ? `${serviceUrl}?mode=${mode}` : serviceUrl, {
        method: 'PUT',
        headers: {
          ...this.headers,
          ...(authTokenCookie && {
            Authorization: `Bearer ${authTokenCookie}`
          }),
          'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
          'Content-Type': 'application/json',
          ...(CONFIG.FEATURE.ENABLE_SKIPEMAILS_HEADER && {
            skipemails: 'true'
          })
        },
        body: profileData
      })
        .then(response => {
          if (response.status >= 200 && response.status <= 299) {
            resolve({ response: { ok: true }, request: updatedProfile });
          } else if (response.status >= 400 && response.status <= 499) {
            return response.json();
          }
          // Internal server error
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ response, request: updatedProfile });
        })
        .then(body => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            response: updateProfileCMSResponse(body),
            request: updatedProfile
          });
        })
        .catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ response: error, request: updatedProfile });
        });
    });
  }

  updateProfileSettings(currentProfile, updatedProfile, partyUid) {
    if (!partyUid) {
      throw new Error('User ID must be provided to profile update');
    }
    let authTokenCookie = readAuthTokenCookie();
    const profileSettings = getProfileSettings(currentProfile, updatedProfile);
    let profileSettingString = getProfileSettingsString(profileSettings);
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      },

      body: JSON.stringify({
        query: `mutation {updateProfileSetting(retailUnit:  ${CONFIG.COUNTRY}, ${profileSettingString}  ) {success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.updateProfileSetting.success) {
          return true;
        } else {
          throw body.errors;
        }
      })
      .catch(error => {
        throw error;
      });
  }

  updateCustomerPersonalInfo(profile, currentProfile) {
    const customerPersonalInfoString = getUpdateCustomerPersonalInfoString(
      profile,
      currentProfile
    );
    let authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      },
      body: JSON.stringify({
        query: `mutation {updateCustomerPersonalInfo(retailUnit:${CONFIG.COUNTRY}, ${customerPersonalInfoString}){success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.updateCustomerPersonalInfo.success) {
          return true;
        } else {
          const error = body?.errors[0]?.errorCode;
          const exception = new Error(error);
          throw exception;
        }
      })
      .catch(error => {
        throw error;
      });
  }

  upsertBillingAddress(newPrimaryAddress, formId) {
    if (!formId) {
      throw new Error('Form ID must be provided to profile update');
    }
    const newPrimaryAddressString = getAddressString(newPrimaryAddress);
    var authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        }),
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        'Content-Type': 'application/json',
        ...(CONFIG.FEATURE.ENABLE_SKIPEMAILS_HEADER && {
          skipemails: 'true'
        })
      },
      body: JSON.stringify({
        query: `mutation {upsertBillingAddress(retailUnit: ${CONFIG.COUNTRY}, ${newPrimaryAddressString}) {success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.upsertBillingAddress.success) {
          return true;
        } else {
          throw body.errors;
        }
      })
      .catch(error => {
        throw error;
      });
  }

  updateHomeAndInterests(homeAndInterest, partyUid) {
    if (!partyUid) {
      throw new Error('User ID must be provided to profile update');
    }
    const homeAndInterestString = getHouseHoldString(homeAndInterest);
    var authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        }),
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        'Content-Type': 'application/json',
        ...(CONFIG.FEATURE.ENABLE_SKIPEMAILS_HEADER && {
          skipemails: 'true'
        })
      },
      body: JSON.stringify({
        query: `mutation {updateHomeAndInterests(retailUnit: ${CONFIG.COUNTRY}, ${homeAndInterestString}) {success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.updateHomeAndInterests.success) {
          return true;
        } else {
          throw body.errors;
        }
      })
      .catch(error => {
        throw error;
      });
  }

  upsertContactMethod(contactMethod, partyUid) {
    if (!partyUid) {
      throw new Error('User ID must be provided to profile update');
    }
    const contactMethodString = getContactMethodString(contactMethod);
    var authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        }),
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        'Content-Type': 'application/json',
        ...(CONFIG.FEATURE.ENABLE_SKIPEMAILS_HEADER && {
          skipemails: 'true'
        })
      },
      body: JSON.stringify({
        query: `mutation {upsertContactMethod(retailUnit: ${CONFIG.COUNTRY}, ${contactMethodString}) {success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.upsertContactMethod.success) {
          return true;
        } else {
          throw body.errors;
        }
      })
      .catch(error => {
        throw error;
      });
  }

  createDeliveryAddress(newDeliveryAddress) {
    const newDeliveryAddressString = getAddressString(newDeliveryAddress);
    var authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        }),
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        'Content-Type': 'application/json',
        ...(CONFIG.FEATURE.ENABLE_SKIPEMAILS_HEADER && {
          skipemails: 'true'
        })
      },
      body: JSON.stringify({
        query: `mutation {createDeliveryAddress(retailUnit: ${CONFIG.COUNTRY}, ${newDeliveryAddressString}) {success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.createDeliveryAddress.success) {
          return true;
        } else {
          throw body.errors;
        }
      })
      .catch(error => {
        throw error;
      });
  }

  deleteDeliveryAddress(partyUid, addressId) {
    if (!partyUid) {
      throw new Error('User ID must be provided to profile update');
    }
    let authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        })
      },

      body: JSON.stringify({
        query: `mutation {deleteDeliveryAddress(retailUnit:  ${CONFIG.COUNTRY}, id: \"${addressId}\" ) {success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.deleteDeliveryAddress.success) {
          return true;
        } else {
          throw body.errors;
        }
      })
      .catch(error => {
        throw error;
      });
  }

  upgrade(currentProfile, updatedProfile, partyUid) {
    const profileData = JSON.stringify(
      updateProfileRequest(currentProfile, updatedProfile)
    );
    var authTokenCookie = readAuthTokenCookie();
    return new Promise((resolve, reject) => {
      fetch(
        `${CONFIG.API.CMS.HOST}/api/profile/irw/${CONFIG.COUNTRY}/${CONFIG.LANGUAGE}/upgrade/${partyUid}/`,
        {
          method: 'PUT',
          headers: {
            ...this.headers,
            ...(authTokenCookie && {
              Authorization: `Bearer ${authTokenCookie}`
            }),
            'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
            'Content-Type': 'application/json'
          },
          body: profileData
        }
      )
        .then(response => {
          if (response.status >= 200 && response.status <= 299) {
            resolve({ response: { ok: true }, request: updatedProfile });
          } else if (response.status >= 400 && response.status <= 499) {
            return response.json();
          }
          // Internal server error
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ response, request: updatedProfile });
        })
        .then(body => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            response: updateProfileCMSResponse(body),
            request: updatedProfile
          });
        })
        .catch(error => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ response: error, request: updatedProfile });
        });
    });
  }

  extractUserInfo(user, decodedToken) {
    let response = new LoginResponse();
    const memberId = user.memberId;
    const firstName = user.firstName;
    const lastName = user.lastName;
    const email = user.email;
    const mobile = user.mobile;
    const phoneVerified = user.phoneVerified;
    const emailVerified = user.emailVerified;
    const loyaltyProgramCode = user.loyaltyProgramCode;
    const preferredStore = user.preferredStore;
    const zipCode = user.zipCode;
    response.authenticated = true;

    response.user = {
      userId: memberId,
      userLogin: email ? email : mobile,
      firstName,
      lastName,
      preferredStore,
      zipCode,
      email,
      mobile,
      partyUid: decodedToken['https://accounts.ikea.com/partyUId'],
      userName: `${user.firstName} ${user.lastName}`,
      phoneVerified,
      emailVerified,
      profileType: this.getLoyaltyProgram(
        loyaltyProgramCode,
        decodedToken['https://accounts.ikea.com/customerType']
      ),
      identifier: !email && mobile && phoneVerified ? 'mobile' : 'email',
      employeeId: decodedToken['https://accounts.ikea.com/employeeID']
    };
    return response;
  }

  getLoyaltyProgram(loyaltyProgramCode, customerType) {
    if (customerType && customerType === 'business') {
      return PROFILE_TYPES.BUSINESS;
    }
    if (loyaltyProgramCode) {
      switch (loyaltyProgramCode.startsWith('FAMILY')) {
        case true:
          return PROFILE_TYPES.FAMILY;
        default:
          return PROFILE_TYPES.REGULAR;
      }
    }
    return PROFILE_TYPES.REGULAR;
  }

  updateDeliveryAddress(address) {
    const AddressData = getAddressString(address);
    var authTokenCookie = readAuthTokenCookie();
    return fetch(`${CONFIG.API.CMS.HOST}/api/profile/graphql`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...this.headers,
        ...(authTokenCookie && {
          Authorization: `Bearer ${authTokenCookie}`
        }),
        'x-client-id': `${CONFIG.API.CMS.CLIENT_ID}`,
        'Content-Type': 'application/json',
        ...(CONFIG.FEATURE.ENABLE_SKIPEMAILS_HEADER && {
          skipemails: 'true'
        })
      },
      body: JSON.stringify({
        query: `mutation {updateDeliveryAddress(retailUnit:  ${CONFIG.COUNTRY}, id: \"${address.addressId}\", ${AddressData} ) {success}}`
      })
    })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          return response.json();
        }
        throw Error();
      })
      .then(body => {
        if (!body.errors && body.data.updateDeliveryAddress.success) {
          return true;
        } else {
          throw body.errors;
        }
      })
      .catch(error => {
        throw error;
      });
  }
}
