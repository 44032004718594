/* eslint-disable no-unused-expressions */
import { ReduxStore } from 'Redux';
import { t, exists } from 'Utils/localization/i18next';
import { getFormValues, getFieldMessages } from 'Redux/forms/formsHelper';
import {
  getAge,
  convertUnifiedDateStringToDateObject,
  getSpecificDateStringInUnifiedFormat
} from 'Utils/date/dateUtils';
import { availableCountryCodeMap } from 'ExtendedForm/PhoneInput/countryCodes';
import {
  FieldRules,
  GroupOfRules
} from '@ingka-group-digital/customer-fields-validation-rules';
import {
  PasswordErrorKeys,
  validateMinLength,
  validateIdenticalChars,
  validateLowerCase,
  validateUpperCase,
  validateNumbers,
  validateSpecialCharacters,
  validateMaxLength
} from './password';

const generalRegex = GroupOfRules.extractValidationRulesByRegex('generalRegex');
const phoneRegex = GroupOfRules.extractValidationRulesByRegex('phoneRegex');
const zipCodeRegex = GroupOfRules.extractValidationRulesByRegex('zipCodeRegex');
const ssnRegex = GroupOfRules.extractValidationRulesByRegex('ssnRegex');

const FieldKeyConstants = {
  /** Names */
  SSN_NUMBER: 'socialSecurityNumber',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  FIRST_NAME_PHONETIC: 'firstNamePhonetic',
  LAST_NAME_PHONETIC: 'lastNamePhonetic',
  FULL_NAME: 'fullName',
  FULL_NAME_PHONETIC: 'fullNamePhonetic',
  ALTERNATE_COLLECTION_FIRST_NAME: 'alternateCollectorFirstName',
  ALTERNATE_COLLECTION_SUR_NAME: 'alternateCollectorSurName',
  SECOND_SUR_NAME: 'secondSurname',
  SECOND_LAST_NAME: 'secondLastName',
  BIRTH_DATE: 'birthDate',
  PREFERRED_LANGUAGE: 'preferredLanguage',

  /** Address */

  ADDRESS_LINE1: 'address1',
  ADDRESS_LINE2: 'address2',
  ADDRESS_LINE3: 'address3',
  ADDRESS_LINE4: 'address4',
  ADDRESS_LINE5: 'address5',
  ADDRESS_LINE6: 'address6',
  CITY: 'cityName',
  POST_CODE: 'zipCode',

  HOUSE_NUMBER: 'houseNumber',
  STATE: 'state',
  COUNTRY_CODE: 'countryCode',

  /** Delivery Address */

  SHIPPING_FIRST_NAME: 'shippingFirstName',
  SHIPPING_LAST_NAME: 'shippingLastName',
  SHIPPING_ADDRESS_LINE1: 'shippingAddressLine1',
  SHIPPING_ADDRESS_LINE2: 'shippingAddressLine2',
  SHIPPING_ADDRESS_LINE3: 'shippingAddressLine3',
  SHIPPING_ADDRESS_CITY: 'shippingCity',
  SHIPPING_POST_CODE: 'shippingZipCode',
  SHIPPING_STATE: 'shippingState',
  SHIPPING_COUNTRY_CODE: 'shippingCountryCode',
  SHIPPING_MOBILE: 'shippingMobileNumber',
  SHIPPING_LANDLINE: 'shippingLandLine',
  SHIPPING_EMAIL: 'shippingEmail',

  /** Contact */

  EMAIL: 'username',
  MOBILE: 'mobile',
  LANDLINE: 'landline',

  /** Business */

  BUSINESS_NAME: 'businessName',
  ORGANIZATION_NUMBER: 'organizationNumber',
  BUSINESS_NAME_PHONETIC: 'businessNamePhonetic',
  FISCAL_CODE: 'fiscalCode',
  TAX_CODE: 'taxId.documentNumber',
  REGULAR_TAX_CODE_TYPE: 'regularTaxCodeType',
  BUSINESS_TAX_CODE_TYPE: 'businessTaxCodeType',
  TAX_IDENTIFIER_NUMBER: 'taxIdentifierNo',
  VAT_BUSINESS_TYPE: 'vatBusinessType',
  BILLING_VAT_PAYING_BUSINESS: 'billingVatPayingBusiness',
  SHIPPING_VAT_PAYING_BUSINESS: 'shippingVatPayingBusiness',

  /** Recipient */

  RECIPIENT_CODE: 'recipientCode',
  RECIPIENT_EMAIL: 'recipientEmail'
};

const getFieldKeyByLabel = value => {
  return Object.keys(FieldKeyConstants).find(
    key => FieldKeyConstants[key] === value
  );
};

const removeFormsPrefix = label => {
  const prefix = 'forms.';
  if (label.startsWith(prefix)) {
    return label.slice(prefix.length);
  }
  return label;
};

const validationMessage = (validatorName, label, message, args) => {
  if (message) {
    return [
      t(message, {
        field:
          CONFIG.FEATURE.VALIDATION &&
          CONFIG.FEATURE.VALIDATION.KEEP_PLACEHOLDER_CAPITALIZATION
            ? t(label)
            : t(label).toLocaleLowerCase(),
        ...args
      })
    ];
  }
  if (label) {
    return [
      t(`forms.validation.${validatorName}`, {
        field:
          CONFIG.FEATURE.VALIDATION &&
          CONFIG.FEATURE.VALIDATION.KEEP_PLACEHOLDER_CAPITALIZATION
            ? t(label)
            : t(label).toLocaleLowerCase(),
        ...args
      })
    ];
  }
  return [t('forms.validation.invalidGeneral')];
};

const addressLineWithApartmentNumber = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.addressLineWithApartmentNumber;
  const { flag } = generalRegex.addressLineWithApartmentNumber;
  const addressLineWithApartmentNumberRegExp = new RegExp(regexp, flag);
  if (!addressLineWithApartmentNumberRegExp.test(value)) {
    return validationMessage('addressLineWithApartmentNumber', label, message);
  }
  return undefined;
};

const birthDate = (
  value = '',
  label,
  {
    minAge = Number.MIN_SAFE_INTEGER,
    maxAge = Number.MAX_SAFE_INTEGER,
    format = 'DD-MM-YYYY',
    message
  }
) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.dateFormat[format];
  const { flag } = generalRegex.dateFormat[format];
  const birthDateRegExp = new RegExp(regexp, flag);
  if (!birthDateRegExp.test(value)) {
    return validationMessage('birthDateFormat', label, message, {
      format
    });
  }

  const formattedDateStr = getSpecificDateStringInUnifiedFormat(value, format);
  const unifiedDateRegexp = generalRegex.date.regexp;
  const unifiedDateRegexFlag = generalRegex.date.flag;
  const dateUnifiedFormat = new RegExp(unifiedDateRegexp, unifiedDateRegexFlag);
  if (!dateUnifiedFormat.test(formattedDateStr)) {
    return validationMessage(
      exists('forms.validation.birthDateInvalid')
        ? 'birthDateInvalid'
        : 'invalid',
      label,
      message,
      {
        format
      }
    );
  }

  const birthDateObj = convertUnifiedDateStringToDateObject(formattedDateStr);
  const age = getAge(birthDateObj);

  if (age < minAge || age > maxAge) {
    const minStr = minAge !== Number.MIN_SAFE_INTEGER ? minAge : '';
    const maxStr = maxAge !== Number.MAX_SAFE_INTEGER ? maxAge : '';
    const delimiterStr = minStr !== '' && maxStr !== '' ? '-' : '';
    const range = `${minStr}${delimiterStr}${maxStr}`;
    return validationMessage('birthDateAge', label, message, { range });
  }
  return undefined;
};

const birthDateExtended = (
  value = '',
  label,
  {
    minAge = Number.MIN_SAFE_INTEGER,
    maxAge = Number.MAX_SAFE_INTEGER,
    format = 'DD-MM-YYYY',
    message
  }
) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.dateFormat[format];
  const { flag } = generalRegex.dateFormat[format];
  const birthDateRegExp = new RegExp(regexp, flag);
  if (!birthDateRegExp.test(value)) {
    return validationMessage('birthDateFormat', label, message, {
      format
    });
  }
  const formattedDateStr = getSpecificDateStringInUnifiedFormat(value, format);
  const unifiedDateRegexp = generalRegex.date.regexp;
  const unifiedDateRegexFlag = generalRegex.date.flag;
  const dateUnifiedFormat = new RegExp(unifiedDateRegexp, unifiedDateRegexFlag);
  if (!dateUnifiedFormat.test(formattedDateStr)) {
    return validationMessage(
      exists('forms.validation.birthDateInvalid')
        ? 'birthDateInvalid'
        : 'invalid',
      label,
      message,
      {
        format
      }
    );
  }

  const birthDateObj = convertUnifiedDateStringToDateObject(formattedDateStr);
  const age = getAge(birthDateObj);

  if (age < minAge) {
    return validationMessage('birthDateMinAge', label, message, { minAge });
  }
  if (age > maxAge) {
    return validationMessage('birthDateMaxAge', label, message, { maxAge });
  }

  return undefined;
};

const alphanumericDashAndSpace = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.alphanumericDashAndSpace;
  const { flag } = generalRegex.alphanumericDashAndSpace;
  const alphanumericDashAndSpaceRegExp = new RegExp(regexp, flag);
  if (!alphanumericDashAndSpaceRegExp.test(value)) {
    return validationMessage('alphanumericDashAndSpace', label, message);
  }
  return undefined;
};

const email = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.email;
  const { flag } = generalRegex.email;
  const emailRegExp = new RegExp(regexp, flag);
  if (!emailRegExp.test(value)) {
    return validationMessage('email', label, message);
  }
  return undefined;
};

const lengthRange = (value = '', label, { min, max, message }) => {
  if (!value.length) {
    return undefined;
  }
  const maxValue = !max ? min : max;

  if (value.length < parseInt(min) || value.length > parseInt(maxValue)) {
    const range = min === maxValue ? min : `${min}-${maxValue}`;
    return validationMessage('lengthRange', label, message, { range });
  }
  return undefined;
};

const mandatory = (value = false, label, { message }) => {
  const newValue = typeof value === 'string' ? value.trim() : value;
  if (!newValue) {
    return validationMessage('mandatory', label, message);
  }
  return undefined;
};

const invalidFieldValueDependency = (
  value = '',
  label,
  { thisFieldValue, otherFieldValue, otherFieldName, message },
  formId
) => {
  const formValues = getFormValues(ReduxStore.getState(), formId);
  if (
    value === thisFieldValue &&
    formValues[otherFieldName] === otherFieldValue
  ) {
    return validationMessage('invalid', label, message);
  }
  return undefined;
};

const mandatoryIfOtherFieldMatchesRegex = (
  value = '',
  label,
  { fieldLabel, otherFieldName, otherFieldLabel, message, regex },
  formId,
  setFormFieldMessages
) => {
  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(ReduxStore.getState(), formId);
  const otherFieldValue = formValues[otherFieldName];
  const isOtherFieldMatchesRegex = new RegExp(regex).test(
    otherFieldValue || ''
  );
  if (!value && isOtherFieldMatchesRegex) {
    return validationMessage('mandatory', label, message, {
      otherField: t(otherFieldLabel).toLocaleLowerCase(),
      field: t(fieldLabel || label).toLocaleLowerCase()
    });
  }

  // If the field currently being validated is valid, the depending field might
  // also be valid. Remove the validation message originating from this validator
  // from the depending field's list of validation messages.
  if (!setFormFieldMessages) {
    return undefined;
  }
  const currentMessages = getFieldMessages(reduxState, formId, otherFieldName);

  const originalMessage = validationMessage(
    'mandatory',
    otherFieldLabel,
    message,
    {
      otherField: t(label).toLocaleLowerCase()
    }
  )[0];

  const filteredMessages = currentMessages.filter(m => m !== originalMessage);
  setFormFieldMessages(formId, otherFieldName, filteredMessages);
  return undefined;
};

const mandatoryIfProfileFieldChanged = (
  value = false,
  label,
  { field, message },
  formId
) => {
  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(reduxState, formId);
  const profileFieldValue =
    typeof reduxState.profile[field] === 'string'
      ? reduxState.profile[field].toLocaleLowerCase()
      : reduxState.profile[field];
  const formValue =
    typeof formValues[field] === 'string'
      ? formValues[field].toLocaleLowerCase()
      : formValues[field];
  const fieldChanged = profileFieldValue !== formValue;
  const newValue =
    typeof value === 'string' ? value.trim().toLowerCase() : value;
  if (fieldChanged && !newValue) {
    return validationMessage('mandatory', label, message);
  }
  return undefined;
};

const matchWithFormField = (value = '', label, { field, message }, formId) => {
  if (!value.length) {
    return undefined;
  }
  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(reduxState, formId);

  if (formValues[field] && value !== formValues[field]) {
    return validationMessage('matchWithFormField', label, message);
  }
  return undefined;
};

const notEqualToFormField = (value = '', label, { field, message }, formId) => {
  if (!value.length) {
    return undefined;
  }
  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(reduxState, formId);
  if (formValues[field] && value === formValues[field]) {
    return validationMessage('notEqualToFormField', label, message);
  }
  return undefined;
};

const notInjectionChars = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.notInjectionChars;
  const { flag } = generalRegex.notInjectionChars;
  const notInjectionCharsRegExp = new RegExp(regexp, flag);
  if (!notInjectionCharsRegExp.test(value)) {
    return validationMessage('notInjectionChars', label, message);
  }
  return undefined;
};

const notNumbers = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.notNumbers;
  const { flag } = generalRegex.notNumbers;
  const notNumbersRegExp = new RegExp(regexp, flag);
  if (!notNumbersRegExp.test(value)) {
    return validationMessage('notNumbers', label, message);
  }
  return undefined;
};

const notNumbersOrSpecialChars = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const regexpForNotNumbers = generalRegex.notNumbers.regexp;
  const flagForNotNumbers = generalRegex.notNumbers.flag;
  const notNumbersRegExp = new RegExp(regexpForNotNumbers, flagForNotNumbers);

  const regexpForNotSpecialChars = generalRegex.notSpecialChars.regexp;
  const flagForNotSpecialChars = generalRegex.notNumbers.flag;
  const notSpecialCharsRegExp = new RegExp(
    regexpForNotSpecialChars,
    flagForNotSpecialChars
  );

  if (!notNumbersRegExp.test(value) || !notSpecialCharsRegExp.test(value)) {
    return validationMessage('notNumbersOrSpecialChars', label, message);
  }
  return undefined;
};

const notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionChars = (
  value = '',
  label,
  { message }
) => {
  if (!value.length) {
    return undefined;
  }
  const {
    regexp
  } = generalRegex.notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionChars;
  const {
    flag
  } = generalRegex.notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionChars;
  const notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionCharsRegExp = new RegExp(
    regexp,
    flag
  );
  if (
    !notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionCharsRegExp.test(
      value
    )
  ) {
    return validationMessage(
      'notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionChars',
      label,
      message
    );
  }
  return undefined;
};

const notOnlySpaceNumberSpecialCharsAndNotInjectionChars = (
  value = '',
  label,
  { message }
) => {
  if (!value.length) {
    return undefined;
  }
  const {
    regexp
  } = generalRegex.notOnlySpaceNumberSpecialCharsAndNotInjectionChars;
  const {
    flag
  } = generalRegex.notOnlySpaceNumberSpecialCharsAndNotInjectionChars;
  const notOnlySpaceNumberSpecialCharsAndNotInjectionCharsRegExp = new RegExp(
    regexp,
    flag
  );
  if (!notOnlySpaceNumberSpecialCharsAndNotInjectionCharsRegExp.test(value)) {
    return validationMessage(
      'notOnlySpaceNumberSpecialCharsAndNotInjectionChars',
      label,
      message
    );
  }
  return undefined;
};

const notOnlySpaceSpecialCharsAndNotInjectionChars = (
  value = '',
  label,
  { message }
) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.notOnlySpaceSpecialCharsAndNotInjectionChars;
  const { flag } = generalRegex.notOnlySpaceSpecialCharsAndNotInjectionChars;
  const notOnlySpaceSpecialCharsAndNotInjectionCharsRegExp = new RegExp(
    regexp,
    flag
  );
  if (!notOnlySpaceSpecialCharsAndNotInjectionCharsRegExp.test(value)) {
    return validationMessage(
      'notOnlySpaceSpecialCharsAndNotInjectionChars',
      label,
      message
    );
  }
  return undefined;
};

const addressLineWithNoNumber = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.addressLineWithNoNumber;
  const { flag } = generalRegex.addressLineWithNoNumber;
  const addressLineWithApartmentNumberRegExp = new RegExp(regexp, flag);
  if (!addressLineWithApartmentNumberRegExp.test(value)) {
    return validationMessage('addressLineWithNoNumber', label, message);
  }
  return undefined;
};

const notSpecialChars = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.notSpecialChars;
  const { flag } = generalRegex.notSpecialChars;
  const notSpecialCharsRegExp = new RegExp(regexp, flag);
  if (!notSpecialCharsRegExp.test(value)) {
    return validationMessage('notSpecialChars', label, message);
  }
  return undefined;
};

const onlyNumbers = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.onlyNumbers;
  const { flag } = generalRegex.onlyNumbers;
  const onlyNumbersRegExp = new RegExp(regexp, flag);
  if (!onlyNumbersRegExp.test(value)) {
    return validationMessage('onlyNumbers', label, message);
  }
  return undefined;
};

const password = (value = '') => {
  const messages = [];
  const isNumbersOrSpecialCharacters =
    validateNumbers.check(value) || validateSpecialCharacters.check(value);
  !validateMinLength.check(value) && messages.push(PasswordErrorKeys[0]);
  !validateMaxLength(value) && messages.push(PasswordErrorKeys[0]);
  !validateIdenticalChars.check(value) && messages.push(PasswordErrorKeys[1]);
  !validateLowerCase.check(value) && messages.push(PasswordErrorKeys[2]);
  !validateUpperCase.check(value) && messages.push(PasswordErrorKeys[3]);
  !isNumbersOrSpecialCharacters && messages.push(PasswordErrorKeys[4]);
  return messages;
};

const passwordRoig = (value = '') => {
  const messages = [];
  !validateMinLength.check(value) && messages.push(PasswordErrorKeys[0]);
  !validateMaxLength(value) && messages.push(PasswordErrorKeys[0]);
  !validateIdenticalChars.check(value) && messages.push(PasswordErrorKeys[1]);
  !validateLowerCase.check(value) && messages.push(PasswordErrorKeys[2]);
  !validateUpperCase.check(value) && messages.push(PasswordErrorKeys[3]);
  !validateNumbers.check(value) && messages.push(PasswordErrorKeys[4]);
  return messages;
};

const groupRegex = (
  value = '',
  label,
  { fieldLabel, otherFieldName, otherFieldLabel, message, regex },
  formId,
  setFormFieldMessages
) => {
  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(ReduxStore.getState(), formId);
  const otherFieldValue = formValues[otherFieldName];
  const isGroupMatchesRegex = new RegExp(regex, 'i').test(
    `${value}${otherFieldValue || ''}`
  );
  if (isGroupMatchesRegex) {
    return validationMessage('invalid', label, message, {
      otherField: t(otherFieldLabel).toLocaleLowerCase(),
      field: t(fieldLabel || label).toLocaleLowerCase()
    });
  }

  // If the field currently being validated is valid, the depending field might
  // also be valid. Remove the validation message originating from this validator
  // from the depending field's list of validation messages.
  if (!setFormFieldMessages) {
    return undefined;
  }
  const currentMessages = getFieldMessages(reduxState, formId, otherFieldName);

  const originalMessage = validationMessage(
    'invalid',
    otherFieldLabel,
    message,
    {
      otherField: t(label).toLocaleLowerCase()
    }
  )[0];

  const filteredMessages = currentMessages.filter(m => m !== originalMessage);
  setFormFieldMessages(formId, otherFieldName, filteredMessages);
  return undefined;
};

const regex = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const fieldKey = getFieldKeyByLabel(removeFormsPrefix(label));
  const validationRules = FieldRules.getValidationRulesByField(
    [fieldKey],
    CONFIG.COUNTRY.toUpperCase(),
    'JavaScript'
  );
  const regexpString = validationRules[0]?.regex?.regexp;
  const regexp = new RegExp(regexpString);
  if (!regexp.test(value)) {
    return validationMessage('regex', label, message);
  }
  return undefined;
};

// Luhn's algoirthm
const isSSNValid = ssn => {
  const sum = [...ssn]
    .reverse()
    .slice(0, 10)
    .map(Number)
    .reduce((previousSum, currentNumber, i) => {
      let digit = currentNumber;
      if (i % 2) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      return previousSum + digit;
    });
  return sum % 10 === 0;
};

const socialSecurityNumber = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const country = CONFIG.COUNTRY.toUpperCase();
  const { regexp } = ssnRegex[country];
  const { flag } = ssnRegex[country];
  const socialSecurityNumberRegExp = new RegExp(regexp, flag);
  if (!socialSecurityNumberRegExp.test(value)) {
    return validationMessage('socialSecurityNumber', label, message);
  }
  if (!isSSNValid(value)) {
    return validationMessage('socialSecurityNumber', label, message);
  }
  return undefined;
};

const zipCode = (value = '', label, args, formId) => {
  if (!value.length) {
    return undefined;
  }

  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(reduxState, formId);
  const country = formValues.addressCountryCode || CONFIG.COUNTRY.toUpperCase();

  const regexp =
    zipCodeRegex?.[country]?.regexp ?? zipCodeRegex?.default?.regexp;
  const flag = zipCodeRegex?.[country]?.flag ?? zipCodeRegex?.default?.flag;

  const zipCodeRegexByCountry = new RegExp(regexp, flag);

  if (!zipCodeRegexByCountry.test(value)) {
    return [
      country === CONFIG.COUNTRY.toUpperCase()
        ? t('forms.validation.zipCode')
        : t('forms.validation.zipCodeInternational', { country })
    ];
  }
  return undefined;
};

const phone = (value = '', label, { phoneType = 'mobile' }, formId) => {
  if (!value.length) {
    return undefined;
  }
  const reduxState = ReduxStore.getState();
  const formValues = getFormValues(reduxState, formId);
  const country =
    formValues[`${phoneType}Country`] || CONFIG.COUNTRY.toUpperCase();

  // since customer-validation-fields-rules expects 'mobileNumber' instead of 'mobile'
  const phoneTypeAdjusted = phoneType === 'mobile' ? 'mobileNumber' : phoneType;

  const regexp =
    phoneRegex?.[phoneTypeAdjusted]?.[country]?.regexp ??
    phoneRegex?.[phoneTypeAdjusted]?.default?.regexp;

  const flag =
    phoneRegex?.[phoneTypeAdjusted]?.[country]?.flag ??
    phoneRegex?.[phoneTypeAdjusted]?.default?.flag;

  const phoneRegexByCountry = new RegExp(regexp, flag);

  if (!phoneRegexByCountry.test(value)) {
    return [
      country === CONFIG.COUNTRY.toUpperCase()
        ? t(`forms.validation.${phoneType}`)
        : t(`forms.validation.${phoneType}International`)
    ];
  }
  return undefined;
};

const emailOrPhone = (value = '', label, { message }) => {
  if (!value.length) {
    return undefined;
  }
  const { regexp } = generalRegex.email;
  const { flag } = generalRegex.email;
  const emailRegExp = new RegExp(regexp, flag);
  if (!/^\+|^\d+$/.test(value) && !emailRegExp.test(value)) {
    return validationMessage('email', label, message);
  }
  if (/^\+|^\d+$/.test(value)) {
    const countryCode = Object.values(availableCountryCodeMap).find(code =>
      value.startsWith(code)
    );
    const country = Object.keys(availableCountryCodeMap).find(
      key => availableCountryCodeMap[key] === countryCode
    );
    const regexpForMobile =
      phoneRegex?.mobile?.[country]?.regexp ??
      phoneRegex?.mobile?.default?.regexp;
    const flagForMobile =
      phoneRegex?.mobile?.[country]?.flag ?? phoneRegex?.mobile?.default?.flag;

    const phoneRegexByCountry = new RegExp(regexpForMobile, flagForMobile);

    if (!phoneRegexByCountry.test(value)) {
      return validationMessage('mobile', label, message);
    }
  }
  return undefined;
};

const childBirthDate = (
  value = '',
  label,
  { required, minAge = 0, maxAge = 18, format, childIndex },
  formId
) => {
  const { noOfChildren = 0 } = getFormValues(ReduxStore.getState(), formId);

  if (noOfChildren < childIndex + 1) {
    // Field should not be visible/validated
    // if "childIndex" exceeds "noOfChildren".
    return undefined;
  }
  if (required) {
    const msg = mandatory(value, label, {
      message: 'forms.validation.mandatoryGeneral'
    });
    if (msg) {
      return msg;
    }
  }
  if (format) {
    const msg = birthDate(value, label, {
      minAge,
      maxAge,
      format,
      message: 'forms.validation.childBirthdate'
    });
    if (msg) {
      return msg;
    }
  }
  return undefined;
};

// Validates Portugal NIF Regex and Module 11 algorithm
const nif = (value = '') => {
  if (!value.length) {
    return undefined;
  }
  const error = [t('forms.validation.nif')];
  const nifRegex = /([123568]\d{1}|45|7[01245789]|9[0189])(\d{7})/;
  if (!nifRegex.test(value)) {
    return error;
  }
  const mod11 =
    Number(
      [9, 8, 7, 6, 5, 4, 3, 2].reduce(
        (total, multiplier, index) => total + value[index] * multiplier,
        0
      )
    ) % 11;
  const checkDigit = mod11 < 2 ? 0 : 11 - mod11;
  if (checkDigit !== Number(value[8])) {
    return error;
  }
  return undefined;
};

// Validates ES NIF,DNI,NIE Regex.
const dni = (value = '') => {
  if (!value.length) {
    return undefined;
  }
  const error = [t('forms.validation.document')];
  const dniRegex = /^((\d{8})|([klm|KLM]\d{7}))[a-zA-Z]{1}$/;
  const nieRegex = /^([xyz|XYZ]\d{7}[a-zA-Z]{1}$)/;
  if (!dniRegex.test(value) && !nieRegex.test(value)) {
    return error;
  }
  return undefined;
};

const passport = (value = '') => {
  if (!value.length) {
    return undefined;
  }
  const error = [t('forms.validation.passport')];
  const passportRegex = /^([a-z]|[A-Z]|[0-9]){1,20}$/;
  if (!passportRegex.test(value)) {
    return error;
  }
  return undefined;
};

export default {
  alphanumericDashAndSpace,
  addressLineWithApartmentNumber,
  birthDate,
  birthDateExtended,
  email,
  emailOrPhone,
  groupRegex,
  lengthRange,
  mandatory,
  invalidFieldValueDependency,
  mandatoryIfOtherFieldMatchesRegex,
  mandatoryIfProfileFieldChanged,
  matchWithFormField,
  notEqualToFormField,
  notInjectionChars,
  notNumbers,
  notNumbersOrSpecialChars,
  notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionChars,
  notOnlySpaceNumberSpecialCharsAndNotInjectionChars,
  notOnlySpaceSpecialCharsAndNotInjectionChars,
  addressLineWithNoNumber,
  notSpecialChars,
  onlyNumbers,
  password,
  passwordRoig,
  regex,
  socialSecurityNumber,
  zipCode,
  phone,
  childBirthDate,
  nif,
  dni,
  passport
};
